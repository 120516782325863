import guards from './guards';

const AdminLayout = () => import(/* webpackChunkName: "layout" */'../layouts/MainLayout');

export default {
    path: '/',
    name: 'MainLayout',
    redirect: '/app/',
    component: AdminLayout,
    beforeEnter: guards.loggedInGuard,
    children: [
        {
            path: '/app/',
            name: 'HomePage',
            component: () => import(/* webpackChunkName: "home" */'../views/HomePage')
        },
        {
            path: '/interview/:id/details/',
            name: 'UserInterviewDetailsPage',
            component: () => import(/* webpackChunkName: "home" */'../views/common-components/InterviewDetailsPage')
        },
        {
            path: '/admin/interview-templates/',
            name: 'AdminInterviewTemplateListingPage',
            component: () => import(/* webpackChunkName: "home" */'../views/admin/interview-management/InterviewTemplateListing')
        },
        {
            path: '/admin/attended-interviews/',
            name: 'AdminAttendedInterviewListingPage',
            component: () => import(/* webpackChunkName: "home" */'../views/admin/interview-management/AttendedInterviewListing')
        },
        {
            path: '/user/interviews/',
            name: 'UserInterviewListingPage',
            component: () => import(/* webpackChunkName: "home" */'../views/user/interview/InterviewListing')
        },
        {
            path: '/user/attended-interviews/',
            name: 'UserAttendedInterviewListingPage',
            component: () => import(/* webpackChunkName: "home" */'../views/user/interview/AttendedInterviewListing')
        },
        {
            path: '/user/interview/:id/start/',
            name: 'UserInterview',
            component: () => import(/* webpackChunkName: "home" */'../views/user/interview/Interview')
        }
    ]
};
