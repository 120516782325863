const prod = process.env.NODE_ENV === 'production';

let baseUrl;
if (prod) {
    baseUrl = '/api/';
} else {
    baseUrl = '/api/';
}

export default {
    auth: {
        login: baseUrl + 'auth/login/',
        logout: baseUrl + 'auth/logout/',
        status: baseUrl + 'auth/status/',

        userDashboard: baseUrl + 'auth/users/dashboard/',
        userCreate: baseUrl + 'auth/users/create/',
        userList: baseUrl + 'auth/users/list/',
        userDelete: baseUrl + 'auth/users/delete/',
        userDetails: baseUrl + 'auth/users/details/',
        userVueSelect: baseUrl + 'auth/users/vue-select/'
    },

    interview: {
        attend: baseUrl + 'interviews/attend/',

        create: baseUrl + 'interviews/create/',
        changeStatus: baseUrl + 'interviews/change-status/',
        list: baseUrl + 'interviews/list/',
        delete: baseUrl + 'interviews/delete/',
        details: baseUrl + 'interviews/details/',

        createTemplate: baseUrl + 'interviews/template-create/',
        listTemplate: baseUrl + 'interviews/template-list/',
        deleteTemplate: baseUrl + 'interviews/template-delete/',

        questions: baseUrl + 'interviews/questions/',
        answer: baseUrl + 'interviews/answer/',
        messages: baseUrl + 'interviews/messages/'
    }
};
