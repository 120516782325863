import FormTextArea from 'lego-framework/src/forms/FormTextArea';

export default {
    name: 'form-textarea-full',
    extends: FormTextArea,
    props: {
        inputClasses: { type: [String, Array], default: 'mt-2' },
        textareaClasses: { type: [String, Array], default: 'h-25vh w-100p' }
        // labelClasses: { type: [String, Array], default: 'text-2 font-weight-600 text-fs-3' }
    }
};
